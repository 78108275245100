import React from 'react';

import 'moment/locale/tr';
import locale from 'antd/es/locale/tr_TR';

import type {DatePickerProps, RangePickerProps} from 'antd/es/date-picker';
import {DatePicker as AntDatePicker, ConfigProvider} from 'antd';

import '@dv/scss/lib/RangePicker.antd.css';
import moment from "moment/moment";

const {RangePicker: AntRangePicker} = AntDatePicker;


const onChange = (
    value: DatePickerProps['value'] | RangePickerProps['value'],
    dateString: [string, string] | string,
) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
};

const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
    console.log('onOk: ', value);
};

const RangePicker: React.FC<RangePickerProps> = ({onChange, showTime, format}: any) => {
    // disabledDate={d => !d || d.isBefore(moment(moment().subtract(6, 'months')).format('YYYY-MM-DD'))}
    return (
        <ConfigProvider locale={locale}>
            <AntRangePicker
                className="datepicker_primary"
                showTime={showTime === false ? showTime : {format: 'HH:mm'}}
                format={format || "DD MMMM YYYY ddd, HH:mm"}
                onChange={onChange}
                onOk={onOk}
                allowClear={true}
            />
        </ConfigProvider>
    )
}

export default RangePicker;

